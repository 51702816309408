import { Grid, GridProps, Heading, Section, ShowMoreButton } from '@/atoms';
import { ArticleGroupStoryblok } from '@/components';
import { buildArticleCardProps } from '@/contexts/articles';
import { ArticleCardProps } from '@/types/article';
import { cls, isNotString } from '@/utils';
import { useState } from 'react';
import { t } from 'ttag';
import { Blok } from '../Blok';
import styles from './ArticleGroup.module.scss';
import { ArticleLarge } from './ArticleLarge';
import { ArticleSmall } from './ArticleSmall';

/**
 * Group of Large Articles
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=326%3A11065
 */

const useGridColumns = (itemCount: number) => {
	const columns: GridProps['columns'] = { base: 1 };

	if (itemCount % 4 === 0) {
		columns.max = 3;
	}
	if (itemCount % 3 === 0) {
		columns.xl = 3;
		columns.lg = 3;
		columns.md = 1;
		columns.base = 1;
	}
	if (itemCount % 3 === 0) {
		columns.max = 3;
		columns.xl = 3;
		columns.lg = 3;
		columns.md = 1;
		columns.base = 1;
	}
	if (itemCount % 2 === 0) {
		columns.max = 2;
		columns.xl = 2;
		columns.lg = 2;
		columns.md = 1;
		columns.base = 1;
	}
	if (itemCount === 1) {
		columns.md = 3;
	}

	return columns;
};

interface BlokProps {
	blok: ArticleGroupStoryblok;
}

const blokProps = ({ blok }: BlokProps): Props => {
	const posts = blok.posts?.filter(isNotString) ?? [];

	const articles = posts.map(buildArticleCardProps);

	return { title: blok.title, articles };
};

interface Props {
	title?: string;
	articles: ArticleCardProps[];
	className?: string;
}

export const ArticleGroup: Blok<Props, BlokProps> = ({ title, articles }) => {
	const firstArticlesAmount = 3;
	const firstArticles = articles?.slice(0, firstArticlesAmount);
	const remainingArticles = articles?.slice(firstArticlesAmount);
	const [showMore, setShowMore] = useState(false);
	const columnValue = Math.min(firstArticlesAmount, firstArticles?.length);
	const columns = useGridColumns(columnValue);

	return (
		<Section paddingX={{ base: 'xl', md: '3xl', lg: '5xl' }}>
			{title && <Heading as="h2" size="h3" title={title} data-testid="article-group-header" marginBottom="3xl" />}
			<Grid columns={columns} colGap="xl" rowGap="md">
				{firstArticles?.map((article, index) => (
					<Grid.Item key={article.slug}>
						<ArticleLarge article={article} className={`article--${index + 1}`} />
					</Grid.Item>
				))}
			</Grid>
			{remainingArticles && remainingArticles.length > 0 && (
				<ShowMoreButton onClick={() => setShowMore(!showMore)} icon={showMore ? 'chevron-up' : 'chevron-down'}>
					{showMore ? t`Visa färre` : t`Visa fler`}
				</ShowMoreButton>
			)}
			<Grid
				className={cls(!showMore ? styles.hidden : undefined, styles.remainingArticles)}
				columns={{ base: 1, md: 3, lg: 3, xl: 4 }}
				colGap="xl"
				rowGap="md"
			>
				{remainingArticles?.map((article) => (
					<Grid.Item key={article.slug}>
						<ArticleSmall article={article} />
					</Grid.Item>
				))}
			</Grid>
		</Section>
	);
};

ArticleGroup.blokProps = blokProps;
