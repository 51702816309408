import { Grid, GridProps } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { CardMediumVideoGroupStoryblok } from '@/components';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { CardMediumVideo, CardMediumVideoProps } from '../CardMediumVideo/CardMediumVideo';
import styles from './CardMediumVideoGroup.module.scss';

interface BlokProps {
	blok: CardMediumVideoGroupStoryblok;
}

const useGridColumns = (itemCount: number, group: boolean) => {
	const columns: GridProps['columns'] = { base: 1 };
	const { hasSubMenuV1 } = useSubMenu();

	columns.max = hasSubMenuV1 || group ? 2 : 4;
	columns.xl = hasSubMenuV1 || group ? 2 : 4;

	if (itemCount === 3 || itemCount === 5) {
		columns.max = 3;
		columns.xl = 3;
	}

	if (itemCount === 2) {
		columns.max = 2;
		columns.xl = 2;
	}

	columns.lg = 2;

	return columns;
};

const blokProps = ({ blok }: BlokProps): Props => ({
	cards: blok.cards.map((card) => CardMediumVideo.blokProps({ blok: card })),
	groupGrid: blok.groupGrid,
	_editable: blok._editable,
});

interface Props {
	cards: CardMediumVideoProps[];
	groupGrid?: boolean;
	_editable?: string;
}

export const CardMediumVideoGroup: Blok<Props, BlokProps> = ({ cards, groupGrid = false, _editable }) => {
	const nrOfCards = cards?.length ?? 0;
	const columns = useGridColumns(nrOfCards, groupGrid);
	return (
		<Grid
			{...editableBlok({ _editable })}
			columns={columns}
			colGap="2xl"
			rowGap="2xl"
			className={cls(groupGrid && styles.groupGrid)}
		>
			{cards.map((card, i) => (
				<Grid.Item key={i}>
					<CardMediumVideo {...card} nrOfCards={nrOfCards} />
				</Grid.Item>
			))}
		</Grid>
	);
};

CardMediumVideoGroup.blokProps = blokProps;
