import { Heading, Icon, Image, Link } from '@/atoms';
import { ImageType, useIntegration } from '@/contexts/Integration';
import { useServerPageConfig } from '@/contexts/config';
import { cls } from '@/utils';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { UserInsights } from '../../NavigationPageIntegrations/IntegrationCard/IntegrationCardDecoration/UserInsights';
import styles from '../IntegrationPage.module.scss';

interface Category {
	categoryId: number;
	categoryNameSv: string;
	categoryNameEn: string;
}

interface Props {
	categories: Category[];
	asLink?: boolean;
	className?: string;
	iconSize?: SizeProp;
}

export const CategoryList: React.FC<Props> = ({ categories, asLink = true, className, iconSize = 'sm' }) => {
	const normalizeCategoryName = (categoryName: string) => {
		return categoryName
			.toLowerCase()
			.replace(/[åä]/g, 'a')
			.replace(/ö/g, 'o')
			.replace(/[^a-z0-9]+/g, '-');
	};

	return (
		<div className={cls([className, styles.categoryList])}>
			<Icon name={'tags'} size={iconSize} className={styles.icon} />
			{categories.map((category, index) =>
				asLink ? (
					<Link
						className={styles.categoryLink}
						key={`categoryLink-${category.categoryId}`}
						href={`/integrationer/?category=${normalizeCategoryName(category.categoryNameSv)}`}
					>
						{category.categoryNameSv}
					</Link>
				) : (
					<div key={`category-name-${index}`}>{category.categoryNameSv}</div>
				),
			)}
		</div>
	);
};

const IntegrationHeader = () => {
	const { integration } = useIntegration();
	const { appsHost: host } = useServerPageConfig();
	const icon = integration?.images.find((image) => image.type === ImageType.ICON);

	if (!integration) return null;

	const { appName, categories } = integration;

	return (
		<header className={styles.header}>
			{icon?.path && (
				<div className={styles.iconContainer}>
					<Image
						width={104}
						height={104}
						className={styles.icon}
						src={`${host}${icon.path}`}
						alt={`${appName} icon`}
						title={appName}
					/>
				</div>
			)}
			<div className={styles.integrationPageHeading}>
				<div>Smart koppling</div>
				<Heading
					marginBottom="none"
					as="h1"
					size="h3"
					title={appName}
					data-testid="single-integration-header-h5"
					className={styles.heading}
				/>
				<div className={styles.insightsBlock}>
					<CategoryList categories={categories} />
					<UserInsights integration={integration} />
				</div>
			</div>
		</header>
	);
};

export default IntegrationHeader;
