import { ImageProps } from '@/atoms';
import { useGlobalMenu } from '@/contexts/menu/GlobalMenuProvider';
import { ImageAsset, ImageAssetProps } from '@/metabloks';
import { cls } from '@/utils';
import { CSSProperties } from 'react';
import styles from './BackgroundImage.module.scss';

interface Props extends Pick<ImageProps, 'objectPosition'> {
	asset: ImageAssetProps;
	className?: string;
	priority?: boolean;
	loading?: 'eager' | 'lazy' | undefined;

	/**
	 * Adds a green faded overlay to image
	 */
	overlay?: boolean;

	/**
	 * From wich direction to add the overlay
	 */
	overlayDirection?: 'to-left' | 'to-right' | 'full' | 'center';

	/**
	 * Keep image in backgroound regardless of viewport width
	 */
	isHeader?: boolean;

	borderRadius?: boolean;

	zIndex?: number;

	/**
	 * Adds a blur filter to the background
	 */
	blur?: boolean;
}

/**
 * BackgroundImage is a background image with an optional faded overlay that will be relative to its parent.
 * Use inside ContainerFluid to make the background image full width.
 */
export const BackgroundImage: React.FC<Props> = ({
	asset,
	className,
	overlay,
	overlayDirection = 'to-right',
	objectPosition = 'center',
	priority,
	loading,
	isHeader = false,
	borderRadius = false,
	zIndex,
	blur = false,
}) => {
	const { menuVersionV3 } = useGlobalMenu();

	let style: CSSProperties = {};

	if (zIndex) {
		style = {
			zIndex,
		};
	}

	return (
		<div
			className={cls(
				styles.container,
				menuVersionV3 && styles.menuVersionV3,
				overlay && styles.withOverlay,
				isHeader && styles.isHeader,
				borderRadius && styles.borderRadius,
				className,
			)}
			style={style}
		>
			<div className={cls(styles.imageContainer, overlay && styles[`direction--${overlayDirection}`])}>
				<ImageAsset
					absolute
					fill
					objectFit="cover"
					objectPosition={objectPosition}
					asset={asset}
					priority={priority}
					loading={loading}
				/>
				{overlay && <div className={styles.overlay} />}
				{blur && <div className={styles.blurOverlay} />}
			</div>
		</div>
	);
};
