import {
	BrandLine,
	Button,
	Card,
	Checkbox,
	Grid,
	Image,
	PageLayout,
	Pagination,
	Section,
	Select,
	SelectOption,
	Text,
	TextField,
} from '@/atoms';
import { CardMedium } from '@/bloks/Card';
import { AccountantCityList } from '@/bloks/Page/NavigationPageFindAccountants/AccountantCityList/AccountantCityList';
import {
	GeoLocationSearch,
	Position,
} from '@/bloks/Page/NavigationPageFindAccountants/GeolocationSearch/GeolocationSearch';
import {
	Choice,
	MultiChoiceFilter,
	filterMatch,
} from '@/bloks/Page/NavigationPageFindAccountants/MultiChoiceFilter/MultiChoiceFilter';
import { CardFiveGroupStoryblok, NavigationPageFindAccountantsStoryblok, SubMenuStoryblok } from '@/components';
import { useAccountants } from '@/contexts/accountants/AccountantsProvider';
import { Accountant } from '@/contexts/accountants/types';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import { ThemeProvider } from '@/contexts/theme';
import { ISbStoryData } from '@/types/storyblok';
import { capitalizeEachWord, cls, fetchApi } from '@/utils';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { t } from 'ttag';
import DynamicComponent from '../../DynamicComponent';
import { AccountantCard } from './AccountantCard/AccountantCard';
import styles from './NavigationPageFindAccountants.module.scss';

interface Props {
	blok: NavigationPageFindAccountantsStoryblok;
	meta: Record<string, any>;
}

export const NavigationPageFindAccountants: React.FC<Props> = ({ blok, meta }) => {
	const contact = blok?.contact as unknown as ISbStoryData<CardFiveGroupStoryblok>;
	const subMenu = blok?.subMenu as unknown as ISbStoryData<SubMenuStoryblok>;
	const MAX_PER_PAGE = blok.customCard?.length ? 11 : 12;
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const { accountants, cities, searchAddress } = useAccountants();
	const [searchField, setSearchField] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [showAll, setShowAll] = useState(true);
	const filterChoices = [
		{
			key: 'bokföring / redovisning',
			whitelist: ['bokföring', 'bokföra', 'redovisning', 'redovisa'],
			blacklist: ['årsredovisning', 'årsbokslut'],
		},
		{ key: 'deklaration', whitelist: ['deklaration', 'deklarera'], blacklist: [] },
		{ key: 'fakturering', whitelist: ['fakturering', 'faktura', 'fakturera'], blacklist: [] },
		{
			key: 'koncern',
			whitelist: ['koncernredovisning', 'koncerner', 'koncernbolag', 'koncernstruktur'],
			blacklist: [],
		},
		{ key: 'lön', whitelist: ['lön'], blacklist: ['lönsam'] },
		{ key: 'moms', whitelist: ['moms'], blacklist: [] },
		{ key: 'skatt', whitelist: ['skatt'], blacklist: [] },
		{ key: 'årsredovisning / bokslut', whitelist: ['bokslut', 'årsredovisning'], blacklist: [] },
	];
	const defaultFilter: Choice[] = [];
	const [selectedFilterChoices, setSelectedFilterChoices] = useState(defaultFilter);

	const [selectedDistance, setSelectedDistance] = useState<string | '50'>('50');
	const distanceOptions: SelectOption[] =
		['10', '30', '50']?.map((distance) => ({
			label: t`${distance} km`,
			id: distance,
			value: distance,
		})) ?? [];
	const selectedDistanceOption = distanceOptions.filter((option) => option.value === selectedDistance)?.[0] || null;

	const [useMyPosition, setUseMyPosition] = useState<boolean>(false);
	const [geoLocationError, setGeoLocationError] = useState<boolean>(false);
	const [accountantsByLocation, setAccountantsByLocation] = useState<Accountant[] | undefined>(undefined);

	const [paginatedAccountants, setPaginatedAccountants] = useState<Accountant[] | null>(null);
	const [searchResult, setSearchResult] = useState<Accountant[]>([]);

	const router = useRouter();
	const query = router.query;
	const { hasSubMenuV1 } = useSubMenu();
	const unFilteredSearchResult = useMyPosition && accountantsByLocation ? accountantsByLocation : accountants;

	useEffect(() => {
		if (query.address) {
			const address = Array.isArray(query.address) ? query.address[0] : query.address;
			setSearchField(address);
			setCurrentPage(1);
			setUseMyPosition(false);
			setShowAll(false);
		} else {
			setSearchField('');
		}
	}, [query]);

	useEffect(() => {
		if (unFilteredSearchResult && (showAll || searchAddress || useMyPosition)) {
			const withinDistance = showAll
				? unFilteredSearchResult
				: unFilteredSearchResult.filter(
						(a) => a.distance === undefined || a.distance < Number(selectedDistance) * 1000,
					);

			if (selectedFilterChoices.length === 0) {
				setSearchResult(withinDistance);
			} else {
				const filteredResult = withinDistance.filter(
					(a) => a.description && filterMatch(selectedFilterChoices, a.description),
				);
				setSearchResult(filteredResult);
			}
			setCurrentPage(1);
		} else {
			setSearchResult([]);
		}
	}, [unFilteredSearchResult, setSearchResult, selectedFilterChoices, selectedDistance]);

	useEffect(() => {
		setTotalPages(Math.ceil(searchResult.length / MAX_PER_PAGE));
		setIsLoading(false);
	}, [searchResult, MAX_PER_PAGE]);

	useEffect(() => {
		if (searchResult.length <= MAX_PER_PAGE) {
			setPaginatedAccountants(searchResult);
			return;
		}
		if (currentPage === 1) {
			setPaginatedAccountants(searchResult.slice(0, MAX_PER_PAGE));
			return;
		}
		const startIndex = (currentPage - 1) * MAX_PER_PAGE;
		const endIndex = MAX_PER_PAGE * currentPage;

		setPaginatedAccountants(searchResult.slice(startIndex, endIndex));
	}, [currentPage, searchResult, MAX_PER_PAGE]);

	const updateRoute = (address: string) => {
		if (address) {
			const query = { ...router.query, address: address };
			return router.replace({ pathname: router.pathname, query }, undefined, { scroll: false });
		}
		return router.replace({ pathname: router.pathname, query: '' }, undefined, { scroll: false });
	};

	const handlePaginationAndScroll = (event: any) => {
		const element = document.getElementById('accountants');
		if (element) {
			element.scrollIntoView({ behavior: 'smooth' });
		}
		handlePagination(event);
	};

	const handlePagination = (event: any) => {
		if (event.currentTarget.value === 'forward') {
			setCurrentPage(currentPage < totalPages ? currentPage + 1 : currentPage);
		} else if (event.currentTarget.value === 'back') {
			setCurrentPage(currentPage !== 1 ? currentPage - 1 : currentPage);
		}
	};

	const handleSearch = (input: string) => {
		if (input === searchAddress) {
			setIsLoading(true);
			setTimeout(() => setIsLoading(false), 500);
			return;
		}
		if (!input) {
			setIsLoading(true);
			setTimeout(() => setIsLoading(false), 500);
			return updateRoute('');
		}
		setIsLoading(true);
		return updateRoute(input);
	};

	const handlePositionSearch = async (position: Position) => {
		setUseMyPosition(true);
		setShowAll(false);
		const result = await fetchApi('accountants-v1', position.latitude, position.longitude);
		setAccountantsByLocation(result.body);
		setCurrentPage(1);
		return updateRoute('');
	};

	const clearPositionSearch = async () => {
		setUseMyPosition(false);
		return updateRoute('');
	};

	const handleShowAll = async () => {
		setShowAll(!showAll);
		setUseMyPosition(false);
		return updateRoute('');
	};
	const renderCustomCardGridItem = (blok: any) => {
		return (
			<Grid.Item key="special-card" className={styles.cardContainer}>
				<ThemeProvider key={blok._uid} theme="yellow">
					<CardMedium key={blok._uid} {...CardMedium.blokProps({ blok, meta })} />
				</ThemeProvider>
			</Grid.Item>
		);
	};

	const renderAccountantCard = (index: number, accountant: Accountant) => {
		const isRenderSpecialCard = paginatedAccountants?.length === 1 || index === 1;

		return (
			<>
				<Grid.Item key={`accountant-${accountant.id}`} className={styles.cardContainer}>
					<AccountantCard accountant={accountant} filterChoices={filterChoices} />
				</Grid.Item>
				{isRenderSpecialCard && blok.customCard?.map((blok: any) => renderCustomCardGridItem(blok))}
			</>
		);
	};

	return (
		<PageLayout subMenu={subMenu} meta={meta} exits={contact}>
			{blok.header?.map((blok: any) => {
				if (searchAddress && accountants.length > 0) {
					const headerBlock = { ...blok };
					headerBlock.h1Part1 = 'Redovisningsbyråer i';
					headerBlock.h1Part2 = capitalizeEachWord(searchAddress);
					return <DynamicComponent key={headerBlock._uid} blok={headerBlock} />;
				} else {
					return <DynamicComponent key={blok._uid} blok={blok} />;
				}
			})}
			<Section id="result" className={cls(hasSubMenuV1 && styles.hasSubMenuV1)} paddingY={{ base: '2xl' }}>
				<div className={styles.filterContainer}>
					<Grid className={styles.searchFieldContainer} columns={{ base: 12 }} colGap="sm" rowGap="sm">
						<Grid.Item colStart={{ base: 1 }} colEnd={{ base: 13, xl: 7 }} className={styles.gridItem}>
							<div className={styles.searchField}>
								<TextField
									id="find-accountants-search"
									bordered
									maxLength={40}
									type="text"
									value={searchField}
									onChange={(e) => setSearchField(e.target.value)}
									onKeyDown={(e) => e.key === 'Enter' && handleSearch(searchField)}
									title={useMyPosition ? t`Din position` : t`Sök plats`}
									disabled={useMyPosition}
								/>
								{useMyPosition && geoLocationError && (
									<div className={styles.geolocationError}>{t`Platsåtkomst har nekats`}</div>
								)}
							</div>
							<div className={styles.iconContainer}>
								<GeoLocationSearch
									useMyPosition={useMyPosition}
									setUseMyPosition={setUseMyPosition}
									geoLocationError={geoLocationError}
									setGeoLocationError={setGeoLocationError}
									onClick={() => setSearchField('')}
									onSearch={handlePositionSearch}
									clearPositionSearch={clearPositionSearch}
								/>
							</div>
							<div className={styles.searchButton}>
								<Button
									className={styles.button}
									onClick={() => handleSearch(searchField)}
									size={'medium'}
									isLoading={isLoading}
									disabled={useMyPosition}
								>{t`Sök`}</Button>
							</div>
						</Grid.Item>

						<Grid.Item
							colStart={{ base: 1, md: 6, xl: 7 }}
							colEnd={{ base: 13 }}
							rowStart={{ base: 3, md: 2, xl: 1 }}
							rowSpan={{ base: 2 }}
						>
							<div className={styles.showAllContainer}>
								<div>
									<Checkbox
										bordered={true}
										checked={showAll}
										className={styles.checkbox}
										onClick={handleShowAll}
										label={t`Visa byråer i hela Sverige`}
									/>
									<Text marginBottom="none" className={styles.showAllText}>
										{t`Det mesta kan idag göras digitalt, så att jobba med en byrå på andra sidan Sverige är enkelt!`}
									</Text>
								</div>
								<div className={styles.mapIcon}>
									<Image src={'/icons/map.svg'} width={28} height={65} alt={t`Redovisningsbyråer i hela Sverige`} />
								</div>
							</div>
						</Grid.Item>
						<Grid.Item colStart={{ base: 1 }} colEnd={{ base: 3 }} rowStart={{ base: 2 }}>
							<Select
								hideXMark={true}
								className={styles.selectDistance}
								data={distanceOptions}
								disabled={showAll}
								onSelect={(value) => setSelectedDistance(value)}
								title={t`Sökradie`}
								aria-label={t`Sökradie`}
								selected={selectedDistanceOption}
								id="find-accountants-distance"
							/>
						</Grid.Item>
						<Grid.Item colSpan={{ base: 12 }} className={styles.multiChoiceContainer}>
							<MultiChoiceFilter
								title={t`Vad behöver du hjälp med?`}
								text={t`Visa endast byråer med följande erbjudande i sin beskrivning`}
								selectedChoices={selectedFilterChoices}
								setSelectedChoices={setSelectedFilterChoices}
								choices={filterChoices}
							/>
						</Grid.Item>
					</Grid>
				</div>
				<div id={'accountants'} />
				{paginatedAccountants && paginatedAccountants.length > 0 ? (
					<div className={styles.accountantCards}>
						<div className={styles.paginationTop}>
							<Pagination onClick={handlePagination} currentPage={currentPage} totalPages={totalPages} />
						</div>

						<Grid columns={{ base: 1, md: 2, xl: 3, max: hasSubMenuV1 ? 3 : 4 }} colGap="xl" rowGap="xl">
							{paginatedAccountants?.map((accountant, index: number) => renderAccountantCard(index, accountant))}
						</Grid>

						<div className={styles.paginationBottom}>
							<Pagination onClick={handlePaginationAndScroll} currentPage={currentPage} totalPages={totalPages} />
						</div>
					</div>
				) : (
					<div className={styles.accountantCards}>
						<Grid columns={{ base: 1, md: 2, xl: 3, max: hasSubMenuV1 ? 3 : 4 }} colGap="xl" rowGap="xl">
							<Grid.Item colSpan={{ base: 1, max: 2 }}>
								<Card layout={'center'} border={true}>
									<Text marginBottom={'lg'} size={'ingress'} bold={true}>{t`Inga resultat`}</Text>
									<Text>{t`Kolla så du stavat rätt, testa att uttöka sökradie eller minska antalet filter`}</Text>
								</Card>
							</Grid.Item>
							{blok.customCard?.map((blok: any) => renderCustomCardGridItem(blok))}
						</Grid>
					</div>
				)}
				<div className={styles.footer}>
					<Grid
						className={styles.gridContainer}
						columns={{ base: 1, lg: blok?.footer?.length || 0 }}
						colGap="md"
						rowGap="xl"
					>
						{blok.footer?.map((blok) => (
							<div key={blok._uid}>
								<BrandLine className={styles.brandLine} />
								<DynamicComponent blok={blok} />
							</div>
						))}
					</Grid>
				</div>
			</Section>
			<AccountantCityList cities={cities} onLinkClick={() => setSearchField('')} />
		</PageLayout>
	);
};
