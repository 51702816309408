import { Grid, Heading, Text, Tooltip } from '@/atoms';
import DynamicComponent from '@/bloks/DynamicComponent';
import { renderRichText } from '@/bloks/RichText';
import { PriceListGroupStoryblok } from '@/components';
import { editableBlok } from '@/utils/storyblok';
import styles from './PriceListGroup.module.scss';

interface Props {
	blok: PriceListGroupStoryblok;
	meta?: {
		hideMonthlyPrice?: boolean;
	};
}

export function PriceListGroup({ blok, meta }: Props) {
	const { title, text, items, subTitle1, subTitle1Tooltip, subTitle2, subTitle2Tooltip, additionalText, _editable } =
		blok;
	return (
		<div className={styles.container} {...editableBlok({ _editable })}>
			<div>
				{title && <Heading title={title} size="h4" as="div" marginBottom="custom" className={styles.title} />}
				<Text>{text}</Text>
			</div>

			{!meta?.hideMonthlyPrice && (
				<Grid
					columns={{ base: 1, sm: '4fr 3fr 3fr 2fr', md: 4, lg: '5fr 3fr 3fr 2fr' }}
					colGap="sm"
					className={styles.gridContainer}
				>
					<Grid.Item></Grid.Item>
					<Grid.Item>
						<div className={styles.subTitleWrapper}>
							<Text marginBottom="none">
								<strong>{subTitle1}</strong>
							</Text>
							{subTitle1Tooltip && (
								<div className={styles.tooltipWrapper}>
									<Tooltip text={subTitle1Tooltip} theme="darkGreen" />
								</div>
							)}
						</div>
					</Grid.Item>
					<Grid.Item>
						<div className={styles.subTitleWrapper}>
							<Text marginBottom="none">
								<strong>{subTitle2}</strong>
							</Text>
							{subTitle2Tooltip && (
								<div className={styles.tooltipWrapper}>
									<Tooltip text={subTitle2Tooltip} theme="darkGreen" />
								</div>
							)}
						</div>
					</Grid.Item>
					<Grid.Item></Grid.Item>
				</Grid>
			)}

			<div>
				{items.map((item) => (
					<DynamicComponent
						blok={item}
						key={item._uid}
						meta={{ subTitle1, subTitle2, hideMonthlyPrice: meta?.hideMonthlyPrice }}
					/>
				))}
			</div>

			<div>{additionalText && <div className={styles.additionalText}>{renderRichText(additionalText)}</div>}</div>
		</div>
	);
}
