import { CardFaqStoryblok } from '@/components';
import { ThemeProvider } from '@/contexts/theme';
import { getThemeInfo } from '@/themes';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { AccordionFaq } from '../Accordion';
import { LinkButton } from '../Button';
import DynamicComponent from '../DynamicComponent';
import common from './CardContact.module.scss';
import styles from './CardFaq.module.scss';

interface Props {
	blok: CardFaqStoryblok;
	meta?: Record<string, any>;
}

export const CardFaq: React.FC<Props> = ({ blok, meta }) => {
	const theme = 'lightGreen';
	const themeInfo = getThemeInfo(theme);

	return (
		<ThemeProvider theme={theme}>
			<div className={cls(styles.card, themeInfo?.styles.bgColor)}>
				<div {...editableBlok(blok)} className={common.container}>
					{blok.header &&
						blok.header.map((head) => {
							return <DynamicComponent key={head._uid} blok={head} meta={meta} />;
						})}
					<div className={common.text}>
						{blok?.faq.length && (
							<AccordionFaq blok={blok?.faq[0]} meta={{ ...meta, compact: true, theme: 'lightGreen' }} />
						)}
					</div>
					{blok.link?.map((button) => <LinkButton key={button._uid} {...LinkButton.blokProps({ blok: button })} />)}
				</div>
			</div>
		</ThemeProvider>
	);
};
