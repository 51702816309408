import { Icon } from '@/atoms';
import { ListStoryblok } from '@/components';
import { useTheme } from '@/contexts/theme';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { IconName } from '../../../icons/types';
import styles from './List.module.scss';

export interface ListMetaProps {
	bold?: boolean;
	iconColor?: '--primary-yellow' | '--primary-green';
	defaultIcon?: IconName;
	iconSize?: SizeProp;
	/**
	 * To restrict number of list items beeing visible
	 */
	maxAmount?: number;
}

interface BlokProps {
	blok: ListStoryblok;
	meta?: ListMetaProps;
}

const blokProps = ({ blok, meta }: BlokProps): ListProps => ({
	listItems: blok.listItems.map((item) => ({
		text: item.text,
	})),
	iconName: blok.icon?.[0]?.name ?? 'check',
	bold: meta?.bold,
	iconColor: meta?.iconColor,
	defaultIcon: meta?.defaultIcon,
	maxAmount: meta?.maxAmount,
	_editable: blok._editable,
});

export interface ListItem {
	text: string;
	listItem?: ListItem[];
}

export interface ListProps {
	listItems: ListItem[];
	iconName: IconName;
	bold?: boolean;
	iconColor?: '--primary-yellow' | '--primary-green';
	defaultIcon?: IconName;
	iconSize?: SizeProp;
	/**
	 * To restrict number of list items beeing visible
	 */
	maxAmount?: number;
	className?: string;
	_editable?: string;
}

export function List({
	listItems,
	iconName,
	bold = false,
	iconColor = '--primary-yellow',
	defaultIcon,
	iconSize = '2x',
	maxAmount,
	className,
	_editable,
}: ListProps) {
	const themeInfo = useTheme();

	if (!listItems) return null;

	return (
		<ul className={cls(styles.list, themeInfo.styles.textColor, className)} {...editableBlok({ _editable })}>
			{listItems?.slice(0, maxAmount).map((listItem: ListItem, index) => (
				<li key={index} className={styles.listItem}>
					{iconName && (
						<Icon
							name={defaultIcon ?? iconName ?? 'circle-check'}
							color={iconColor}
							size={iconSize}
							className={cls(styles.icon, !iconColor && themeInfo.styles.iconColor)}
						/>
					)}
					{bold ? <strong>{listItem.text}</strong> : listItem.text}
				</li>
			))}
		</ul>
	);
}

List.blokProps = blokProps;
