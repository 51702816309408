import { BrandStripes, Carousel, ContainerFluid, Divider, Grid, Heading, Image, Link, Section, Text } from '@/atoms';
import DynamicComponent from '@/bloks/DynamicComponent';
import { IntegrationPageStoryblok } from '@/components';
import { ImageType, Integration, Image as IntegrationImage } from '@/contexts/Integration';
import { useIntegration } from '@/contexts/Integration/IntegrationProvider';
import { useIntegrator } from '@/contexts/Integration/IntegratorProvider';
import { useServerPageConfig } from '@/contexts/config';
import { markdownToRichtext } from 'storyblok-markdown-richtext';
import React from 'react';
import { t } from 'ttag';
import { renderRichText } from '../../RichText';
import { IntegrationCard } from '../NavigationPageIntegrations/IntegrationCard/IntegrationCard';
import ContactInformation from './Components/ContactInformation';
import CustomerReviews from './Components/CustomerReviews';
import IntegrationHeader from './Components/IntegrationHeader';
import IntegrationPrice from './Components/IntegrationPrice';
import StickyScroll from './Components/StickyScroll';
import TagCloud from './Components/TagCloud';
import UniqueSellingPoints from './Components/UniqueSellingPoints';
import styles from './IntegrationPage.module.scss';

interface CarouselImageProps {
	image?: IntegrationImage;
	host?: string;
	index?: number;
}

const CarouselImage: React.FC<CarouselImageProps> = ({ image, host, index }) => {
	if (!image) {
		return <div></div>;
	}

	return (
		<div data-order={image.order}>
			<Image
				src={`${host}${image.path}`}
				alt={`Bild ${index} för integration`}
				width={500}
				height={326}
				loading="lazy"
				layout="responsive"
				className={styles.sliderImage}
			/>
		</div>
	);
};

interface Props {
	blok: IntegrationPageStoryblok;
}

export const IntegrationPage: React.FC<Props> = ({ blok }) => {
	const { integrations, integration } = useIntegration();
	const { integrator } = useIntegrator();

	const { appsHost: host } = useServerPageConfig();
	const { exits } = blok;

	const isCompanyPage = integrations && integrations.length > 0;

	const company: { companyName: string; apps: Integration[]; categories: string } = {
		companyName: '',
		apps: [],
		categories: '',
	};

	if (isCompanyPage) {
		company.companyName = integrations[0].contactInformation.companyName;
		company.apps = integrations;
		const categories = integrations.map((integration: Integration) => integration.categories).flat();
		const uniqueCategories = Array.from(new Set(categories.map((category) => category.categoryId))).map((id) =>
			categories.find((category) => category.categoryId === id),
		);

		company.categories = uniqueCategories.map((category) => category?.categoryNameSv).join(', ');
	}

	if (!integrator) {
		return null;
	}

	const integrationImages = integration?.images
		.filter((image: IntegrationImage) => image.type !== ImageType.ICON)
		.sort((imageA: IntegrationImage, imageB: IntegrationImage) => imageA.order - imageB.order);

	const summaryTitle = integration?.summaryTitle ?? '';
	const about = markdownToRichtext(integration?.about ?? '');
	const summary = markdownToRichtext(integration?.summary ?? '');
	const howTo = markdownToRichtext(integration?.howTo ?? '');

	const carouselImages: JSX.Element[] =
		integrationImages?.reduce<JSX.Element[]>((acc: JSX.Element[], image: IntegrationImage, index: number) => {
			if (index % 2 === 0) {
				const nextImage = integrationImages[index + 1];
				acc.push(
					<div key={`image-${index}`} className={styles.slideStyle}>
						<CarouselImage image={image} host={host} index={index} />
						<CarouselImage image={nextImage} host={host} index={index + 1} />
					</div>,
				);
			}

			return acc;
		}, []) ?? [];

	return (
		<>
			{company.companyName ? (
				<ContainerFluid>
					<Section paddingX={{ base: 'md', xl: '4xl' }} paddingY={{ base: '5xl' }} className={styles.companyHeader}>
						<BrandStripes />
						<Heading as="div" size="h5" className={styles.companyName} title={company.categories} />
						<Heading
							as="h1"
							title={`${(integrations ?? []).length > 1 ? 'Smarta' : 'Smart'} ${
								(integrations ?? []).length > 1 ? 'kopplingar' : 'koppling'
							} från ${company.companyName}`}
						/>
					</Section>

					<Section className={styles.container} paddingX={{ base: 'md', xl: '4xl' }} paddingY={{ base: 'xl' }}>
						<Grid className={styles.integrationContainer} columns={{ base: 1, md: 2, xl: 3 }} colGap="xl" rowGap="xl">
							{integrations?.map((integration: Integration) => (
								<Grid.Item key={integration.appId}>
									<IntegrationCard integration={integration} />
								</Grid.Item>
							))}
						</Grid>
					</Section>

					<Section className={styles.container} paddingX={{ base: 'md', xl: '4xl' }} paddingY={{ base: 'xl' }}>
						<Divider margin="xl" />
						<Grid className={styles.integrationContainer} columns={{ base: 1, md: 2, xl: 2 }} colGap="xl" rowGap="xl">
							<Grid.Item>
								{integrator?.companyLogoUrl && (
									<Image
										src={`${host}${integrator?.companyLogoUrl}`}
										height={140}
										width={400}
										alt={`Logotyp för ${integrator?.companyName}`}
										className={styles.companyLogo}
									/>
								)}
								<Heading as="h2" size="h5" title={`Om ${company.companyName}`} />
								{integrator?.about && <p>{integrator?.about}</p>}
								{integrator?.successStory && <p>{integrator?.successStory}</p>}
							</Grid.Item>
							<Grid.Item>
								<ContactInformation integrator={integrator} integration={integration} showAccessRights={false} />
							</Grid.Item>
						</Grid>
					</Section>
				</ContainerFluid>
			) : (
				<ContainerFluid>
					<Section className={styles.container} paddingX={{ base: 'md', xl: '4xl' }} paddingY={{ base: '5xl' }}>
						<Grid className={styles.integrationGrid} columns={{ base: 1, lg: '9fr 3fr' }} colGap={'4xl'}>
							<Grid.Item data-testid="single-integration-content">
								<Grid rowGap="xl" columns={{ base: 1, md: 1, lg: 1 }} className={styles.mainContent}>
									<Grid.Item>
										<IntegrationHeader />
									</Grid.Item>
									<Grid.Item>
										<Heading as="h2" title={summaryTitle} />
										<Text as="div" className={styles.summary}>
											{renderRichText(summary)}
										</Text>
									</Grid.Item>
									<Grid.Item>
										<Carousel showIndicators={false} showArrows={true} centerSlidePercentage={100} infiniteLoop={true}>
											{carouselImages}
										</Carousel>
									</Grid.Item>
									<Grid.Item>
										<Heading as="h3" size="h4" title={t`Mer information`} />
										<Text as="div">{renderRichText(about)}</Text>
									</Grid.Item>
									<Grid.Item>
										<Heading as="h3" size="h4" title={t`Så fungerar kopplingen med Fortnox`} />
										<Text as="div">{renderRichText(howTo)}</Text>

										{integration?.readMoreUrl && (
											<Link
												href={integration.readMoreUrl}
											>{`Läs mer på ${integration.contactInformation.companyName}'s hemsida`}</Link> // Fixa detta!!
										)}
									</Grid.Item>
									<Grid.Item>
										<CustomerReviews reviews={integration?.customerReviews} />
									</Grid.Item>
								</Grid>
							</Grid.Item>

							<Grid.Item className={styles.sidebar}>
								<StickyScroll>
									<UniqueSellingPoints />
									<TagCloud />
									<IntegrationPrice />
									<ContactInformation integrator={integrator} integration={integration} />
								</StickyScroll>
							</Grid.Item>
						</Grid>
					</Section>
				</ContainerFluid>
			)}

			{exits?.map((item) => <DynamicComponent key={item._uid} blok={item} />)}
		</>
	);
};
