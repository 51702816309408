import { Grid } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { CardProfile } from '@/bloks/Card';
import { CalendarStoryblok, CardProfileStoryblok, ProfileGroupStoryblok } from '@/components';
import { Calendar } from '../Calendar';
import styles from './ProfileGroup.module.scss';

interface Props {
	items: (CardProfileStoryblok | CalendarStoryblok)[];
	meta: { zone?: number };
}

interface BlokProps {
	blok: ProfileGroupStoryblok;
	meta: { zone?: number };
}

const blokProps = ({ blok, meta }: BlokProps): Props => {
	const items = blok.items.flatMap((story) => {
		if (typeof story !== 'string') return [story.content];
		if (process.env.NODE_ENV !== 'production') console.warn(`missing.resolve.relations ProfileGroup.items`);
		return [];
	});
	return { items, meta };
};

export const ProfileGroup: Blok<Props, BlokProps> = ({ items, meta }: Props) => {
	const columns = { base: 1, lg: 2, xl: 2 };

	return (
		<Grid columns={columns} rowGap="2xl" className={styles.grid}>
			{items.map((item) => (
				<Grid.Item key={item._uid}>
					{item.component === 'CardProfile' ? (
						<CardProfile {...CardProfile.blokProps({ blok: item, meta })} />
					) : (
						<Calendar {...Calendar.blokProps({ blok: item })} />
					)}
				</Grid.Item>
			))}
		</Grid>
	);
};

ProfileGroup.blokProps = blokProps;
