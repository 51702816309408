import { ContainerFluid, Heading, Section } from '@/atoms';
import { HeaderCommon } from '@/bloks';
import { Blok } from '@/bloks/Blok';
import {
	HeaderCommonStoryblok,
	ProductCatalogItemStoryblok,
	ProductCatalogPackageItemStoryblok,
	SectionComparisonTableStoryblok,
} from '@/components';
import { useGlobalMenu } from '@/contexts/menu/GlobalMenuProvider';
import { ImpressionTrackingProvider } from '@/contexts/piwik/ImpressionTrackingProvider';
import { useSectionComparisonTableSegmentDescriptions } from '@/contexts/products/SectionComparisonProvider';
import { ThemeProvider } from '@/contexts/theme';
import { getChildTheme, getThemeInfo } from '@/themes';
import { cls } from '@/utils';
import { useRef } from 'react';
import { PriceBox } from './PriceBox';
import styles from './SectionComparisonTable.module.scss';
import { SegmentRow } from './SegmentRow';

export interface Table {
	name: string[];
	rows: Row[];
	slug: string;
}

export interface Row {
	name: string;
	columns: string[];
}

interface BlokProps {
	blok: SectionComparisonTableStoryblok;
	meta: { zone?: number | undefined; containerFoldoutButton: JSX.Element };
}

const blokProps = ({ blok, meta }: BlokProps): Props => {
	return {
		header: blok?.header,
		theme: blok.theme,
		primary: blok.primary,
		showOrderButtons: blok.showOrderButtons,
		products: blok.products,
		segments: blok.segments,
		containerFoldoutButton: meta.containerFoldoutButton,
		zone: meta?.zone,
	};
};

interface Props {
	header?: HeaderCommonStoryblok[];
	theme?: 'primary' | 'lightGreen';
	primary?: string;
	showOrderButtons?: boolean;
	products: ProductCatalogPackageItemStoryblok[] | ProductCatalogItemStoryblok[] | any[];
	segments: SectionComparisonTableStoryblok['segments'];
	containerFoldoutButton?: JSX.Element;
	zone?: number;
}

export const SectionComparisonTable: Blok<Props, BlokProps> = ({
	header,
	theme,
	primary,
	showOrderButtons,
	products,
	containerFoldoutButton,
	zone,
}) => {
	const { segmentDescriptions, tables } = useSectionComparisonTableSegmentDescriptions();
	const { menuVersionV3 } = useGlobalMenu();
	const stickyRef = useRef<HTMLDivElement>(null);
	const productsInTable: any[] = products?.map((product) => product.product);
	const linkButtons = products
		?.filter((product) => {
			if (product?.linkButton != undefined) {
				return { product, linkButton: product.linkButton[0] };
			}
		})
		.map((product) => product.linkButton?.[0]);
	const hasTheme = theme ? theme : 'primary';
	const themeInfo = getThemeInfo(hasTheme);
	const infoBannerActive = useGlobalMenu().hasInfoBanner;
	const secondaryTheme = hasTheme == 'primary' ? 'secondary' : hasTheme;
	const secondaryThemeInfo = getThemeInfo(secondaryTheme);
	const childTheme = getChildTheme(hasTheme);
	const childThemeInfo = getThemeInfo(childTheme);
	let updatePrimary = primary;
	if (hasTheme == 'lightGreen') {
		updatePrimary = undefined;
	}

	return (
		<ImpressionTrackingProvider
			value={{
				contentName: `${zone}|SectionComparisonTable`,
				contentTarget: 'multiple interactions possible',
				threshold: 0.1,
			}}
		>
			<ContainerFluid>
				<Section paddingX={{ base: 'md', md: 'xl' }} paddingY={{ base: 'xl', md: '4xl', lg: '5xl' }}>
					{header?.map((header) => (
						<HeaderCommon key={header._uid} {...HeaderCommon.blokProps({ blok: header })} marginBottom="3xl" />
					))}
					<div className={cls(styles.comparisionTableContainer, menuVersionV3 && styles.menuVersionV3)}>
						<div
							className={cls(
								styles.row,
								styles.stickyHeader,
								infoBannerActive && styles.withInfoBannerActive,
								hasTheme != 'primary' && styles.stickyRowNeutral,
							)}
							ref={stickyRef}
						>
							<div
								className={cls(styles.col, styles.hideHeaderBoxOnMobile, hasTheme != 'primary' && styles.removeHeight)}
							></div>
							{productsInTable?.map((product, index) => {
								return (
									<ThemeProvider
										theme={index.toString() === primary ? hasTheme : secondaryTheme}
										key={`table-header-${product?.uuid}`}
									>
										<div
											className={cls(
												styles.col,
												index.toString() === updatePrimary ? styles.primary : styles.secondary,
												index.toString() === updatePrimary
													? themeInfo.styles.bgColor
													: secondaryThemeInfo.styles.bgColor,
											)}
										>
											<div className={cls(styles.headerAndPriceBox)}>
												<Heading
													as="div"
													size="h4"
													title={product?.content?.name}
													marginBottom="none"
													className={cls(
														styles.productHeader,
														styles.textAlignCenter,
														index.toString() === updatePrimary && styles.primeHeader,
													)}
												/>
												<PriceBox
													product={product}
													meta={{ zone: zone }}
													showOrderButtons={showOrderButtons}
													theme={hasTheme}
													primary={index.toString() === updatePrimary}
													linkButton={linkButtons?.[index]}
												/>
											</div>
										</div>
									</ThemeProvider>
								);
							})}
						</div>
						<div className={styles.segmentRows}>
							{tables?.map?.((segment: Table, segmentIndex: number) => {
								return (
									<div key={segmentIndex} className={styles.section}>
										<div
											className={cls(
												styles.row,
												styles.stickyRow,
												infoBannerActive && styles.withInfoBannerActive,
												styles.mobileSegmentRow,
											)}
										>
											<div
												className={cls(
													styles.col,
													styles.headerCol,
													styles.sectionRowColumn,
													hasTheme != 'primary' && styles.whiteRow,
												)}
											>
												<Heading
													as="div"
													size="h5"
													title={segment?.name}
													marginBottom="none"
													className={styles.rowHeader}
												/>
											</div>

											{hasTheme == 'primary' &&
												productsInTable?.map((_product, index) => (
													<div
														className={cls(
															styles.col,
															index.toString() === primary ? childThemeInfo.styles.row : secondaryThemeInfo.styles.row,
															styles.hideOnMobile,
															styles.sectionRowColumn,
														)}
														key={index}
													></div>
												))}
										</div>

										{segment?.rows?.map((row: Row, rowIndex: number) => {
											const isLastRow = rowIndex == segment.rows.length - 1;
											return (
												<SegmentRow
													key={rowIndex}
													isLastRow={isLastRow}
													row={row}
													primary={updatePrimary}
													secondaryThemeInfo={secondaryThemeInfo}
													childThemeInfo={childThemeInfo}
													descriptions={segmentDescriptions}
													tableSlug={segment.slug}
													theme={hasTheme}
												/>
											);
										})}
									</div>
								);
							})}
						</div>
						{containerFoldoutButton && <div className={styles.containerFoldoutButton}>{containerFoldoutButton}</div>}
					</div>
				</Section>
			</ContainerFluid>
		</ImpressionTrackingProvider>
	);
};

SectionComparisonTable.blokProps = blokProps;
