import { Section } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { KpOrderForm } from '@/bloks/Form';
import { HeaderCommon, HeaderCommonProps } from '@/bloks/Other';
import { KpOrderFormStoryblok, SectionKpFormStoryblok } from '@/components';
import { editableBlok } from '@/utils/storyblok';
import styles from './SectionKpForm.module.scss';

interface BlokProps {
	blok: SectionKpFormStoryblok;
}

const blokProps = ({ blok }: BlokProps): Props => ({
	header: blok.header?.[0] && HeaderCommon.blokProps({ blok: blok.header[0] }),
	form: blok.form?.[0] && blok.form[0],
	_editable: blok._editable,
});

interface Props {
	header?: HeaderCommonProps;
	form?: KpOrderFormStoryblok;
	_editable?: string;
}

export const SectionKpForm: Blok<Props, BlokProps> = ({ header, form, _editable }) => {
	return (
		<Section {...editableBlok({ _editable })}>
			{header && <HeaderCommon {...header} marginBottom="3xl" />}
			{form && (
				<div className={styles.formWrapper}>
					<KpOrderForm blok={form} />
				</div>
			)}
		</Section>
	);
};

SectionKpForm.blokProps = blokProps;
