import { Icon, LinkAnchor } from '@/atoms';
import { IconStoryblok, LinkItemStoryblok } from '@/components';
import { editableBlok } from '@/utils/storyblok';
import { Blok } from '../../Blok';
import { buildLinkProps } from '../../Button/LinkButton';
import styles from './LinkItem.module.scss';

interface BlokProps {
	blok: LinkItemStoryblok;
}

const blokProps = ({ blok }: BlokProps): Props => {
	const linkProps = blok.link && buildLinkProps(blok.link);

	const props: Props = {
		...linkProps,
		_editable: blok._editable,
		icon: blok.icon ? blok.icon[0] : undefined,
		title: blok.text,
		linktype: blok.link?.linktype ?? 'url',
	};

	return props;
};

interface Props {
	_editable?: string;
	href?: string;
	linktype: string;
	className?: string;
	rel?: string;
	testID?: string;
	title?: string;
	icon?: IconStoryblok;
}

/**
 * Link items used in richText.
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=326%3A11065
 */
export const LinkItem: Blok<Props, BlokProps> = ({
	_editable,
	className,
	href,
	icon,
	testID,
	title,
	linktype,
	...rest
}) => {
	let { rel } = rest;
	let target;

	if (linktype === 'asset' || linktype === 'url') {
		target = '_blank';
	}

	if (rel == null && target === '_blank') {
		rel = 'noopener noreferrer';
	}

	return (
		<div className={styles.item} data-testid={testID} {...editableBlok({ _editable })}>
			{href && (
				<LinkAnchor
					href={href}
					hoverEffect
					data-testid={testID}
					title={title}
					className={styles.link}
					target={target}
					rel={rel}
				>
					{icon?.name && <Icon name={icon?.name} size="1x" className={styles.icon} />}
					{title}
					{target === '_blank' && <Icon name="external-link" size="1x" className={styles.external} />}
				</LinkAnchor>
			)}
		</div>
	);
};

LinkItem.blokProps = blokProps;
