import React, { createContext, useContext } from 'react';
import { Integration, IntegrationCategory } from './types';

/**
 * List of integrations from App Market
 */
export const useIntegrations = () => {
	return useContext(Context);
};

interface ContextProps {
	integrations?: Integration[];
	featuredIntegrations?: Integration[];
	categories: IntegrationCategory[] | undefined;
}

const Context = createContext<ContextProps>({
	integrations: [],
	featuredIntegrations: [],
	categories: [],
});

interface Props {
	children: React.ReactNode;
	integrations?: Integration[];
	featuredIntegrations?: Integration[];
	categories?: IntegrationCategory[];
}

export const IntegrationListProvider: React.FC<Props> = ({
	children,
	integrations,
	featuredIntegrations,
	categories,
}) => {
	const value = {
		integrations,
		featuredIntegrations,
		categories,
	};

	return <Context.Provider value={value}>{children}</Context.Provider>;
};

Context.displayName = 'Integrations Context';
