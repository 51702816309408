import { Icon } from '@/atoms';
import { PhoneLinkStoryblok } from '@/components';
import { Themes } from '@/types';
import styles from './PhoneLinkButton.module.scss';

interface BlokProps {
	blok: PhoneLinkStoryblok;
}

interface Props {
	text: string;
	number: string;
	displayPhoneIcon?: boolean;
	theme?: Themes['theme'];
	onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

/*
 * Used to create a link with the tel: attribute
 */
const blokProps = ({ blok }: BlokProps): Props => ({
	text: blok.text,
	number: blok.phoneNumber,
	displayPhoneIcon: blok.displayPhoneIcon,
});

export function PhoneLink({ text, number, displayPhoneIcon, onClick }: Props) {
	const hrefString = `tel:+46${number.replace(/^0-9+/, '')}`;
	return (
		<a href={hrefString} className={styles.link} onClick={onClick}>
			{displayPhoneIcon && <Icon name={'phone'} type={'light'} color={'--primary-green'} size="sm" />}
			{text}
		</a>
	);
}

PhoneLink.blokProps = blokProps;
