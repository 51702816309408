import { GridProps } from '@/atoms/Grid';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import { isOdd } from '@/utils/isOdd';

export const useCardMediumGridColumns = (itemCount: number, group: boolean) => {
	const { hasSubMenuV1 } = useSubMenu();
	const columns: GridProps['columns'] = { base: 1, md: 2 };

	if (group) {
		if (isOdd(itemCount)) {
			return { ...columns, lg: 4 };
		}

		return columns;
	}

	if (itemCount % 3 === 0 && !isOdd(itemCount)) {
		return { ...columns, lg: hasSubMenuV1 ? 2 : 3, xl: 3 };
	}

	if (itemCount % 4 === 0) {
		return { ...columns, lg: 4 };
	}

	if (itemCount % 2 === 0) {
		return columns;
	}

	if (isOdd(itemCount)) {
		if (itemCount === 7) {
			columns.lg = 3;
		}

		if (itemCount === 5) {
			columns.max = 6;
			columns.lg = hasSubMenuV1 ? 2 : 6;
		}

		if (itemCount % 3 === 0) {
			columns.lg = 3;
		}

		columns.md = 4;

		return columns;
	}
};
