import { BrandLine, ContainerFluid, Grid, Heading, Section } from '@/atoms';
import { LinkButton } from '@/bloks/Button';
import { renderRichText } from '@/bloks/RichText';
import { LinkButtonStoryblok, StorySectionLargeStoryblok } from '@/components';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import { ImageAsset, ImageAssetProps } from '@/metabloks';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import styles from './StorySectionLarge.module.scss';

interface Props {
	blok: StorySectionLargeStoryblok;
}

/**
 * Section component that renders a big title, text and optional linkButtons and image
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=293%3A9496
 */
export const StorySectionLarge: React.FC<Props> = ({ blok }) => {
	const { smallTitle, titlePart1, titlePart2, image, text, linkButtons } = blok;
	const { hasSubMenuV1 } = useSubMenu();

	const layout = blok.imagePosition || 'without-image';
	const theme = 'white';

	return (
		<ContainerFluid theme={theme}>
			<Section {...editableBlok(blok)} paddingX={{ base: 'md', md: '3xl' }} paddingY={{ base: '3xl' }}>
				<Grid
					className={cls(
						styles.container,
						hasSubMenuV1 && styles.hasSubMenuV1,
						styles[`layout--${layout}`],
						styles[`theme--${theme}`],
					)}
					columns={{ base: 1, lg: image?.filename ? 2 : 1 }}
					colGap="3xl"
					rowGap="3xl"
				>
					{(titlePart1 || titlePart2 || text) && (
						<Grid.Item className={cls(styles.content, !image?.filename && styles.contentWithoutImage)}>
							{titlePart1 && <BrandLine />}

							{smallTitle && (
								<Heading
									as="div"
									size="h6"
									className={styles.smallTitle}
									title={smallTitle}
									data-testid="story-section-large-header-h6"
									marginBottom="xl"
								/>
							)}
							{titlePart1 && (
								<Heading as="h2" data-testid="story-section-large-h2" title={titlePart1} titlePart2={titlePart2} />
							)}

							{text && <div className={styles.text}>{renderRichText(text)}</div>}

							{linkButtons &&
								linkButtons.map((button: LinkButtonStoryblok) => (
									<LinkButton
										className={styles.linkButton}
										key={button._uid}
										{...LinkButton.blokProps({ blok: button })}
										variant="text"
									/>
								))}
						</Grid.Item>
					)}

					{image?.filename && (
						<Grid.Item className={styles.imageContainer}>
							<div className={styles.image}>
								<ImageAsset
									absolute
									asset={image as ImageAssetProps}
									objectFit="cover"
									fill
									objectPosition="center"
									filters="smart"
								/>
							</div>
						</Grid.Item>
					)}
				</Grid>
			</Section>
		</ContainerFluid>
	);
};
