import { BackgroundImage, BrandLine, ContainerFluid, Grid, Heading, Section, Text } from '@/atoms';
import { LinkToggle, LinkToggleProps } from '@/bloks/Button/LinkToggle';
import { CardSmallImage } from '@/bloks/Card';
import { renderRichText } from '@/bloks/RichText';
import {
	AssetStoryblok,
	CardSmallImageStoryblok,
	HeaderProductV2Storyblok,
	ThemeContrastStoryblok,
	ThemeV2Storyblok,
} from '@/components';
import { useAnimationContext } from '@/contexts/Animations';
import { ImpressionTrackingProvider } from '@/contexts/piwik/ImpressionTrackingProvider';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import React from 'react';
import styles from './HeaderProductV2.module.scss';

interface BlokProps {
	blok: HeaderProductV2Storyblok;
	meta?: {
		containerFoldoutButton?: JSX.Element;
	};
}

const blokProps = ({ blok, meta }: BlokProps): HeaderProductProps => {
	const { backgroundPattern } = blok;
	return {
		title: blok?.title,
		label: blok?.label,
		shortText: blok?.shortText,
		cards: blok?.cardGroup[0]?.cards ?? [],
		linkToggle: blok.linkToggle?.[0] && LinkToggle.blokProps({ blok: blok.linkToggle[0] }),
		cardTitleSize: blok?.cardGroup[0]?.titleSize ?? 'h4',
		containerFoldoutButton: meta?.containerFoldoutButton,
		backgroundImage: blok?.backgroundImage,
		mobileBackgroundImage: blok?.mobileBackgroundImage,
		theme: blok.theme?.[0]?.theme,
		backgroundPattern,
		addContrast: blok.addContrast?.[0]?.theme,
		_editable: blok._editable,
	};
};

interface HeaderProductProps {
	title: string;
	cardTitleSize: 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
	label?: string;
	shortText?: any;
	cards: CardSmallImageStoryblok[];
	linkToggle?: LinkToggleProps;
	containerFoldoutButton?: JSX.Element;
	backgroundImage?: AssetStoryblok;
	mobileBackgroundImage?: AssetStoryblok;
	theme?: ThemeV2Storyblok['theme'];
	backgroundPattern?: boolean;
	addContrast?: ThemeContrastStoryblok['theme'];
	_editable?: string;
}

export const HeaderProductV2 = ({
	title,
	label,
	cards,
	linkToggle,
	addContrast,
	shortText,
	containerFoldoutButton,
	backgroundImage,
	mobileBackgroundImage,
	theme,
	backgroundPattern = false,
	cardTitleSize,
	_editable,
}: HeaderProductProps) => {
	const nrOfCards = cards?.length ?? 0;
	const cardsWithLongText = cards?.some((card) => card.text && card.text.length > 135);
	const textLayout = cardsWithLongText ? 'left' : 'center';
	const selectedTheme = theme || 'darkGreen';
	const { addAnimation } = useAnimationContext();
	const contentName = '1|HeaderProduct';
	const contentPiece = title;
	const hasBackgroundImage = !!backgroundImage?.id || !!mobileBackgroundImage?.id;
	return (
		<ImpressionTrackingProvider value={{ contentName, contentPiece }}>
			<ContainerFluid
				marginBottom="none"
				theme={selectedTheme}
				backgroundPattern={backgroundPattern}
				className={cls(styles.content, addAnimation && 'animation')}
				contrast={addContrast}
				{...editableBlok({ _editable })}
			>
				{backgroundImage && (
					<BackgroundImage
						asset={backgroundImage}
						objectPosition="center top fixed"
						priority={true}
						className={cls(styles.backgroundImage, !mobileBackgroundImage?.id && styles.showDesktopImage)}
					/>
				)}

				{mobileBackgroundImage?.id && (
					<BackgroundImage
						asset={mobileBackgroundImage}
						objectPosition="center top fixed"
						priority={true}
						className={styles.mobileBackgroundImage}
					/>
				)}

				<Section as="header" padding={{ base: 'none' }}>
					<div className={styles.contentContainer}>
						<div className={styles.headerText}>
							<BrandLine className={cls(styles.brandLine, !label && styles.brandlineMargin)} />
							{label && <Heading addContrast={hasBackgroundImage} as="div" size="h6" title={label} />}
							<Heading addContrast={hasBackgroundImage} marginBottom="xl" as="h1" title={title} />
							{shortText?.content?.[0].content && (
								<Text as="div" size="ingress" className={styles.text} marginBottom="xl">
									{renderRichText(shortText, { textSize: 'ingress', addContrast: hasBackgroundImage })}
								</Text>
							)}
						</div>
						<div className={styles.gridContainer}>
							<Grid
								className={styles.grid}
								rowGap="xl"
								colGap="xl"
								columns={{
									base: 1,
									lg: nrOfCards > 3 ? 2 : nrOfCards,
									max: nrOfCards > 4 ? 4 : nrOfCards,
								}}
							>
								{cards?.map((card, index) => {
									return (
										<Grid.Item className={styles.card} key={index}>
											<CardSmallImage
												image={card?.image}
												title={card?.title}
												text={card?.text}
												className={styles.cardImage}
												textLayout={textLayout}
												noBackground
												titleSize={cardTitleSize}
												addContrast={hasBackgroundImage}
											/>
										</Grid.Item>
									);
								})}
							</Grid>
						</div>

						<div className={styles.linkToggle}>
							{linkToggle && <LinkToggle {...linkToggle} addContrast={hasBackgroundImage} />}
						</div>

						{containerFoldoutButton && containerFoldoutButton}
					</div>
				</Section>
			</ContainerFluid>
		</ImpressionTrackingProvider>
	);
};

HeaderProductV2.blokProps = blokProps;
