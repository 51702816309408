import { ShortTextStoryblok } from '@/components';
import { useTheme } from '@/contexts/theme';
import { cls } from '@/utils';
import React, { CSSProperties } from 'react';
import styles from './ShortText.module.scss';

interface Props {
	blok: ShortTextStoryblok;
	meta: { maxWidth: string };
}

export function ShortText({ blok, meta }: Props) {
	const alignment = blok.alignment ?? 'right';
	const themeInfo = useTheme();

	const style = {
		textAlign: alignment,
		maxWidth: meta.maxWidth ?? '100%',
		float: alignment !== 'center' ? alignment : 'none',
	} as CSSProperties;

	return (
		<p style={style} className={cls(styles.text, themeInfo.styles.textColor)}>
			{blok.text ?? ''}
		</p>
	);
}

export default ShortText;
