import { Heading } from '@/atoms';
import { useIntegration } from '@/contexts/Integration';
import React from 'react';
import styles from './UniqueSellingPoints.module.scss';

const UniqueSellingPoints: React.FC = () => {
	const { integration } = useIntegration();

	return (
		<div className={styles.uniqueSellingPoints}>
			<Heading as="h3" size="h4" title={integration?.uniqueSellingPoints.title ?? ''} />
			<ul>
				{integration?.uniqueSellingPoints?.points &&
					integration?.uniqueSellingPoints?.points
						.filter((point) => point)
						.map((point, index) => <li key={`point-${index}`}>{point}</li>)}
			</ul>
		</div>
	);
};

export default UniqueSellingPoints;
