import { ContainerFluid, Grid, Section } from '@/atoms';
import { ArticleMiniGroup, ArticleMiniGroupProps } from '@/bloks/Article';
import { Blok } from '@/bloks/Blok';
import { CardAttractImage, CardAttractImageProps } from '@/bloks/Card';
import { ArticleMiniGroupStoryblok, CardAttractImageStoryblok, SectionArticleCompactStoryblok } from '@/components';
import { editableBlok } from '@/utils/storyblok';

interface BlokProps {
	blok: SectionArticleCompactStoryblok;
}

const blokProps = ({ blok }: BlokProps): Props => ({
	layout: blok.layout,
	twoColumnItems: blok.twoColumnItems?.map((item) => {
		if (item.component === 'CardAttractImage') {
			return CardAttractImage.blokProps({ blok: item as CardAttractImageStoryblok });
		}
		return ArticleMiniGroup.blokProps({ blok: item as ArticleMiniGroupStoryblok });
	}),
	threeColumnItems: blok.threeColumnItems?.map((item) => ArticleMiniGroup.blokProps({ blok: item })),
	_editable: blok._editable,
});

interface Props {
	layout?: '50-50' | '33-33-33';
	twoColumnItems?: (CardAttractImageProps | ArticleMiniGroupProps)[];
	threeColumnItems?: ArticleMiniGroupProps[];
	_editable?: string;
}

export const SectionArticleCompact: Blok<Props, BlokProps> = ({
	layout = '50-50',
	twoColumnItems,
	threeColumnItems,
	_editable,
}) => {
	const containCardAttractImage = twoColumnItems?.some((item) => item.component === 'CardAttractImage');
	const columns =
		layout === '50-50'
			? { base: 1, md: containCardAttractImage ? 1 : 2, lg: containCardAttractImage ? '2fr 1fr' : 2 }
			: { base: 1, md: 2, lg: 3 };
	return (
		<ContainerFluid {...editableBlok({ _editable })}>
			<Section>
				<Grid columns={columns} colGap="3xl" rowGap="3xl">
					{layout === '50-50' &&
						twoColumnItems?.map((item) => {
							if (item.component === 'CardAttractImage') {
								return (
									<Grid.Item key={item._uid}>
										<CardAttractImage {...item} />
									</Grid.Item>
								);
							}
							return (
								<Grid.Item key={item._uid}>
									<ArticleMiniGroup {...item} />
								</Grid.Item>
							);
						})}

					{layout === '33-33-33' &&
						threeColumnItems?.map((item) => (
							<Grid.Item key={item._uid}>
								<ArticleMiniGroup {...item} />
							</Grid.Item>
						))}
				</Grid>
			</Section>
		</ContainerFluid>
	);
};

SectionArticleCompact.blokProps = blokProps;
