import { Icon } from '@/atoms';
import { LinkButton } from '@/bloks/Button';
import { GlobalMenuLinkGroupStoryblok, GlobalMenuSpaceStoryblok, LinkButtonStoryblok } from '@/components';
import { useGlobalMenu } from '@/contexts/menu/GlobalMenuProvider';
import { useSearchV2 } from '@/contexts/search/AddSearchProviderV2';
import { ThemeProvider } from '@/contexts/theme';
import { SearchWidgetV2 } from '@/metabloks';
import { getThemeInfo } from '@/themes';
import { cls } from '@/utils';
import React, { useRef, useState } from 'react';
import { t } from 'ttag';
import styles from './MenuContentMobile.module.scss';

interface Props {
	linkGroups?: GlobalMenuLinkGroupStoryblok[];
	className?: string;
}

export const MenuContentMobile: React.FC<Props> = ({ linkGroups, className }) => {
	const search = useSearchV2();
	const { isMenuOpen } = useGlobalMenu();
	const firstLinkGroup = linkGroups?.[0];
	const [openSubGroupId, setOpenSubGroupId] = useState<string[]>([firstLinkGroup?._uid || '']);
	const theme = 'menuContent';
	const themeInfo = getThemeInfo(theme);

	const handleClick = (_uid: string) => {
		if (openSubGroupId.includes(_uid)) {
			setOpenSubGroupId(openSubGroupId.filter((id) => id !== _uid));
		} else {
			setOpenSubGroupId([...openSubGroupId, _uid]);
		}
	};

	return (
		<ThemeProvider theme={theme}>
			<div className={cls(styles.menuContent, themeInfo.styles.bgColor, isMenuOpen && styles.isMenuOpen, className)}>
				{linkGroups?.map((group) => {
					const open = openSubGroupId.includes(group._uid);
					return <LinkGroup {...group} open={open} key={group._uid} onClick={handleClick} />;
				})}

				<div>{search.ready && <SearchWidgetV2 />}</div>
			</div>
		</ThemeProvider>
	);
};

interface LinkGroupProps {
	_uid: string;
	title: string;
	mainLinks?: LinkButtonStoryblok[];
	links: (LinkButtonStoryblok | GlobalMenuSpaceStoryblok)[];
	onClick: (_uid: string) => void;
	open?: boolean;
}

const LinkGroup: React.FC<LinkGroupProps> = ({ _uid, title, mainLinks, links, onClick, open }) => {
	const { isSearchOpen } = useSearchV2();
	const { isMenuOpen } = useGlobalMenu();
	const refInner = useRef<HTMLDivElement | null>(null);

	const height = refInner.current?.offsetHeight;

	const tabIndex = isMenuOpen && !isSearchOpen ? 0 : -1;

	return (
		<div className={styles.group}>
			<button
				className={styles.titleWrapper}
				onClick={() => onClick(_uid)}
				aria-label={t`Öppna menynivå`}
				tabIndex={tabIndex}
			>
				<span className={styles.title}>{title}</span>
				<Icon name="chevron-right" size="1x" color="--text-dark" className={cls(styles.icon, open && styles.rotate)} />
			</button>

			<div className={cls(styles.links, open && styles.open)} style={{ height: open ? height : 0 }}>
				<div ref={refInner}>
					<div className={styles.mainLinks}>
						{mainLinks?.map((link) => (
							<LinkButton
								key={link._uid}
								{...LinkButton.blokProps({ blok: link })}
								variant="link"
								size="large"
								tabIndex={tabIndex}
							/>
						))}
					</div>
					<div className={styles.subLinks}>
						{links?.map((item) => {
							if (item.component === 'GlobalMenuSpace') {
								return <div className={styles.space} key={item._uid} />;
							}

							return (
								<LinkButton
									key={item._uid}
									{...LinkButton.blokProps({ blok: item })}
									variant="link"
									tabIndex={tabIndex}
								/>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};
