import { Grid } from '@/atoms';
import DynamicComponent from '@/bloks/DynamicComponent';
import { TabTextWithImageStoryblok } from '@/components';
import { ImageAsset } from '@/metabloks';
import { editableBlok } from '@/utils/storyblok';
import classNames from 'classnames';
import sectionTabsStyles from './SectionTabs.module.scss';
import styles from './TabTextWithImage.module.scss';

interface Props {
	blok: TabTextWithImageStoryblok;
	display?: boolean;
}
const ImageGridItem: React.FC<Props> = ({ blok }) => (
	<Grid.Item className={styles.gridItemImage}>
		<div className={styles.image}>
			{blok?.image?.filename && <ImageAsset absolute asset={blok?.image} fill objectFit={'cover'} />}
		</div>
	</Grid.Item>
);

export const TabTextWithImage: React.FC<Props> = ({ blok, display }) => {
	return (
		<div
			className={classNames(display ? sectionTabsStyles.display : sectionTabsStyles.hidden, sectionTabsStyles.panel)}
			{...editableBlok(blok)}
		>
			<div className={styles.container}>
				<Grid columns={{ base: 1, lg: 2 }}>
					{blok.leftSideImage && <ImageGridItem blok={blok} />}
					<Grid.Item className={styles.textContainer}>
						{blok.header?.map((header) => <DynamicComponent key={header._uid} blok={header} />)}
					</Grid.Item>
					{!blok.leftSideImage && <ImageGridItem blok={blok} />}
				</Grid>
			</div>
		</div>
	);
};
