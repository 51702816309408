import { ContainerFluid, Grid, Section } from '@/atoms';
import DynamicComponent from '@/bloks/DynamicComponent';
import { HeaderCommon } from '@/bloks/Other';
import { SectionAccordionStoryblok } from '@/components';
import { ImpressionTrackingProvider } from '@/contexts/piwik/ImpressionTrackingProvider';
import { cls } from '@/utils';
import styles from './SectionAccordion.module.scss';

interface Props {
	blok: SectionAccordionStoryblok;
	meta?: { zone?: number };
}

export const SectionAccordion: React.FC<Props> = ({ blok, meta }) => {
	const { accordion } = blok;
	const theme = blok?.theme?.[0]?.theme ?? 'white';
	const nrOfAccordions = accordion?.length;
	const rowGap = accordion.filter((a) => a.component === 'AccordionRichtext').length > 0 ? '3xl' : undefined;

	const header = blok.header?.find((h) => h.titlePart1);
	const contentPiece = header?.titlePart2 ? `${header.titlePart1} ${header.titlePart2}` : header?.titlePart1;

	return (
		<ImpressionTrackingProvider value={{ contentName: `${meta?.zone}|SectionAccordion`, contentPiece }}>
			<ContainerFluid theme={theme}>
				<Section>
					<div className={cls(styles.content, nrOfAccordions === 2 ? styles['layout--two-columns'] : '')}>
						{header && (
							<HeaderCommon key={header._uid} marginBottom="xl" {...HeaderCommon.blokProps({ blok: header })} />
						)}

						<Grid columns={{ base: 1, lg: nrOfAccordions }} className={styles.grid} rowGap={rowGap}>
							{accordion?.map((accordion) => (
								<Grid.Item key={accordion._uid}>
									<DynamicComponent blok={accordion} meta={{ compact: true, theme, inSection: true }} />
								</Grid.Item>
							))}
						</Grid>
					</div>
				</Section>
			</ContainerFluid>
		</ImpressionTrackingProvider>
	);
};
