import { Tooltip } from '@/atoms';
import { Scope } from '@/contexts/Integration';
import React from 'react';
import { t } from 'ttag';
import styles from './ScopesTooltip.module.scss';

const Scopes: React.FC<Props> = ({ scopes }) => {
	if (!scopes) return null;

	return (
		<div className={styles.scopeList}>
			<h5>{t`Integrationen begär åtkomst till:`}</h5>
			<ul className={styles.scopeList}>
				{scopes.map((scope) => (
					<li key={`scope-${scope.scopeId}`}>{scope.scopeNameSv}</li>
				))}
			</ul>
		</div>
	);
};

interface Props {
	scopes?: Scope[] | null;
}

export const ScopesTooltip: React.FC<Props> = ({ scopes }) => {
	return <Tooltip data-loglabel={'scopes-tooltip'} text={<Scopes scopes={scopes} />} />;
};
