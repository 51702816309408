import { GridProps } from '@/atoms';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import { isOdd } from '@/utils/isOdd';

export function useCardSmallGridColumns(itemCount: number, group: boolean): GridProps['columns'] {
	const { hasSubMenuV1 } = useSubMenu();
	const columns: GridProps['columns'] = { base: 1, md: 2 };

	if (group) {
		return columns;
	}

	if (itemCount % 4 === 0) {
		columns.lg = hasSubMenuV1 ? 2 : 4;

		return columns;
	}

	if (itemCount % 3 === 0 && !isOdd(itemCount)) {
		columns.lg = 3;
		return columns;
	}

	if (itemCount % 2 === 0) {
		return columns;
	}

	if (isOdd(itemCount)) {
		if (itemCount === 7) {
			columns.max = 8;
			columns.lg = hasSubMenuV1 ? 2 : 8;
		}

		if (itemCount === 5) {
			columns.max = 6;
			columns.lg = hasSubMenuV1 ? 2 : 6;
		}

		if (itemCount % 3 === 0) {
			columns.max = 3;
			columns.lg = hasSubMenuV1 ? 2 : 3;
		}

		columns.md = 4;

		return columns;
	}
}
