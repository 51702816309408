import React, { useRef } from 'react';

type StickyScrollProps = {
	children: React.ReactNode;
	className?: string;
};

const StickyScroll: React.FC<StickyScrollProps> = ({ children, className }) => {
	const containerRef = useRef<HTMLDivElement>(null);

	return (
		<div ref={containerRef} className={`relative ${className || ''}`} style={{ height: '100%' }}>
			<div
				style={{ position: 'sticky', top: '8rem', zIndex: 10, display: 'flex', flexDirection: 'column', gap: '32px' }}
			>
				{children}
			</div>
		</div>
	);
};

export default StickyScroll;
