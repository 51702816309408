import { ConversionPageStoryblok } from '@/components';
import { editableBlok } from '@/utils/storyblok';
import DynamicComponent from '../DynamicComponent';

interface Props {
	blok: ConversionPageStoryblok;
}

export const ConversionPage = ({ blok }: Props) => {
	const sections = [...(blok.header || []), ...(blok.sections || [])];
	return (
		<main {...editableBlok(blok)}>
			{sections?.map((blok, index) => <DynamicComponent blok={blok} key={blok._uid} meta={{ zone: index + 1 }} />)}
		</main>
	);
};
