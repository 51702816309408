import {
	BrandSymbol,
	ContainerFluid,
	Divider,
	Grid,
	GridProps,
	Heading,
	LinkAnchor,
	Logo,
	Section,
	Text,
} from '@/atoms';
import {
	DownloadAppGroupStoryblok,
	GlobalFooterStoryblok,
	LinkButtonGroupStoryblok,
	LinkButtonStoryblok,
	SocMeLinkGroupStoryblok,
	TextFooterStoryblok,
} from '@/components';
import { ImpressionTrackingProvider } from '@/contexts/piwik/ImpressionTrackingProvider';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { usePiwikPro } from '@piwikpro/next-piwik-pro';
import React from 'react';
import { t } from 'ttag';
import { SocMeLinkGroup } from '../Button';
import { buildLinkProps } from '../Button/LinkButton';
import { DownloadAppGroup, TextFooter } from '../Other';
import styles from './GlobalFooter.module.scss';

const useGridColumns = (itemCount: number) => {
	const columns: GridProps['columns'] = { base: 1 };

	if (itemCount % 5 === 0) {
		columns.max = 5;
		columns.lg = 5;
		columns.md = 2;
	}

	if (itemCount % 4 === 0) {
		columns.max = 4;
		columns.lg = 4;
	}
	if (itemCount % 3 === 0) {
		columns.xl = 3;
	}
	if (itemCount % 2 === 0) {
		columns.md = 2;
	}
	return columns;
};

interface Props {
	blok: GlobalFooterStoryblok;
}
export const GlobalFooter: React.FC<Props> = ({ blok }) => {
	const linkGroupColumns = useGridColumns(blok.linkGroups?.length ?? 0);
	const actionsColumns = useGridColumns(blok.actions?.length ?? 0);

	// PIWIK IMPRESSION TRACKING
	const { ContentTracking, DataLayer } = usePiwikPro();
	const contentName = `GlobalFooter`;
	const contentPiece = 'Footer';
	const contentTarget = 'multiple interactions possible';

	function trackContentInteraction(targetText: string) {
		DataLayer.push({
			event: 'contentInteraction',
			contentName,
			contentPiece,
			contentTarget,
			contentInteraction: 'Click',
			data: { clickElementText: targetText },
		});
		ContentTracking.trackContentInteraction('contentInteraction', contentName, contentPiece, contentTarget);
	}

	const renderActions = (action: DownloadAppGroupStoryblok | TextFooterStoryblok | SocMeLinkGroupStoryblok) => {
		if (action.component === 'DownloadAppGroup') {
			return <DownloadAppGroup blok={action} />;
		}

		if (action.component === 'TextFooter') {
			return <TextFooter blok={action} />;
		}

		if (action.component === 'SocMeLinkGroup') {
			return <SocMeLinkGroup {...SocMeLinkGroup.blokProps({ blok: action })} />;
		}

		return null;
	};

	return (
		<ImpressionTrackingProvider value={{ contentName, contentPiece, contentTarget }}>
			<ContainerFluid theme="footer">
				<Section
					id="footer"
					as="footer"
					lang="sv"
					{...editableBlok(blok)}
					className={styles.container}
					data-testid="global-footer"
					paddingX={{ base: 'xl', md: '3xl', lg: '5xl' }}
					paddingY={{ base: '5xl' }}
				>
					<div className={styles.intro}>
						<div className={styles.logo} data-testid="footer-logo">
							<Logo size="162.5x35.5" />
						</div>
						<Text size="ingress" className={styles.shortText} marginBottom="custom">
							{blok.shortText}
						</Text>
					</div>

					<Grid as="ul" columns={linkGroupColumns} className={styles.linkGroupWrapper} rowGap="2xl">
						{blok.linkGroups?.map((linkGroup: LinkButtonGroupStoryblok) => (
							<Grid.Item key={linkGroup._uid} as="li" {...editableBlok(linkGroup)}>
								<Heading
									as="div"
									size="h5"
									title={linkGroup.title || ' '}
									className={styles.linkGroupHeading}
									data-testid="footer-group-header-h5"
									marginBottom="lg"
								/>
								<ul className={styles.linkGroup}>
									{linkGroup.linkButtons?.map((linkButton: LinkButtonStoryblok) => (
										<li key={linkButton._uid} {...editableBlok(linkButton)} className={styles.linkItem}>
											<LinkAnchor
												{...buildLinkProps(linkButton?.link)}
												hoverEffect
												className={styles.linkItemAnchor}
												onClick={() => {
													trackContentInteraction(linkButton.text ?? '');
												}}
											>
												{linkButton.text}
											</LinkAnchor>
										</li>
									))}
								</ul>
							</Grid.Item>
						))}
					</Grid>
					<Divider color="--custom-gray" margin="3xl" />
					<Grid
						columns={{
							...actionsColumns,
							lg: blok.actions?.length ?? 1,
							xl: blok.linkGroups?.length ?? 1,
							max: blok.linkGroups?.length ?? 1,
						}}
						className={styles.actions}
						rowGap="2xl"
					>
						{blok.actions?.map((action, index) => (
							<React.Fragment key={action._uid}>
								{blok.linkGroups?.length === 5 && index === 2 && <Grid.Item className={styles.emptyGridItem} />}
								<Grid.Item className={styles.actionItem}>{renderActions(action)}</Grid.Item>
							</React.Fragment>
						))}
					</Grid>
					<Divider color="--custom-gray" margin="3xl" />
					<div className={styles.bottomRow}>
						<ul className={styles.bottomLinkList}>
							{blok.bottomLinks?.map((bottomLink: LinkButtonStoryblok) => (
								<li key={bottomLink._uid}>
									<LinkAnchor
										{...buildLinkProps(bottomLink?.link)}
										hoverEffect
										className={styles.bottomLinkItem}
										onClick={() => {
											trackContentInteraction(bottomLink.text ?? '');
										}}
									>
										{bottomLink.text}
									</LinkAnchor>
								</li>
							))}
							<li id="ppms_cm_privacy_settings">
								<button
									id="ppms_cm_privacy_settings_button"
									className={cls(styles.textVariant)}
									onClick={() => {
										trackContentInteraction(t`Cookie-inställningar`);
									}}
								>
									<span>{t`Cookie-inställningar`}</span>
								</button>
							</li>
						</ul>
						<div className={styles.brand}>
							<div className={styles.copyright} data-testid="copyright-text">
								{new Date().getFullYear()} {blok.copyright}
							</div>
							<div className={styles.brandSymbol}>
								<BrandSymbol />
							</div>
						</div>
					</div>
				</Section>
			</ContainerFluid>
		</ImpressionTrackingProvider>
	);
};
