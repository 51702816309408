import { ContainerFluid, Grid, Section } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { CardSquare, CardSquareProps } from '@/bloks/Card/CardSquare';
import { SectionSquareCardsStoryblok } from '@/components';
import { ThemeProvider } from '@/contexts/theme';
import { ThemeName, getThemeInfo } from '@/themes';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import styles from './SectionSquareCards.module.scss';

interface BlokProps {
	blok: SectionSquareCardsStoryblok;
	meta?: { zone?: number };
}

export interface SectionSquareCardsProps {
	cards: CardSquareProps[];
	_editable?: string;
	zone?: number;
}

const blokProps = ({ blok, meta }: BlokProps): SectionSquareCardsProps => ({
	cards: blok.cards.map((card: any) => CardSquare.blokProps({ blok: card })),
	_editable: blok._editable,
	zone: meta?.zone,
});

export const SectionSquareCards: Blok<SectionSquareCardsProps, BlokProps> = ({ cards, _editable, zone }) => {
	const cardThemes: ThemeName[] = ['lightPurple', 'support'];
	const backgroundOne = getThemeInfo(cardThemes[0]);
	const backgroundTwo = getThemeInfo(cardThemes[1]);

	return (
		<ContainerFluid className={styles.container}>
			<div className={cls(styles.backgroundOne, backgroundOne.styles.bgColor)} />
			<div className={cls(styles.backgroundTwo, backgroundTwo.styles.bgColor)} />
			<Section {...editableBlok({ _editable })} padding={{ base: 'none' }}>
				<Grid columns={{ base: 1, lg: 2 }}>
					{cards.map((card, index) => {
						return (
							<Grid.Item key={card._uid}>
								<ThemeProvider theme={cardThemes[index]}>
									<CardSquare {...card} zone={zone} />
								</ThemeProvider>
							</Grid.Item>
						);
					})}
				</Grid>
			</Section>
		</ContainerFluid>
	);
};

SectionSquareCards.blokProps = blokProps;
